* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  background-color: theme("colors.dark");
  color: theme("colors.text");
  scroll-behavior: smooth;
}
.App {
  max-width: 1512px;
  margin: 0 auto;
}

.isActive {
  width: 30px;
  height: 3px;
  background-color: #218bdc;
}

.card_data,
.service_card_data {
  background: #ffffff;
  box-shadow: 6px 6px 12px #d6d6d6, -6px -6px 12px #ffffff;
  border-radius: 18px;
  padding: 20px;

  &:hover {
    transform: translateY(-10px);
    transition: 0.5s;
  }
}

.card_data:nth-child(2) {
  background: #218bdc;
  color: #ffffff;
  transform: translateY(-40px);
}

@media screen and (max-width: 968px) {
  .card_data:nth-child(2) {
    transform: translateY(00px);
  }
}
/* White color */
/* rgba(254,252,232,1); */

/* Orange background */
/* rgba(208,111,0,1) */

.icon {
  background-color: whitesmoke;
  color: #373737;
  border-radius: 50%;
  padding: 5px;
  min-width: 24px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #218bdc;
    color: whitesmoke;
  }
}

.icon2 {
  min-width: 30px;
  color: #218bdc;
}

@media (max-width: 992px) {
  .br {
    display: none;
    color: #152a3c;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 200px;
  right: 20px;
  z-index: 9999;
  background-color: #25d366;
  color: white;
  padding: 15px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 3px 4px 3px #999;

  &:hover {
    background-color: #128c7e;
  }
}

@media (max-width: 700px) {
  .whatsapp-button {
    bottom: 50px;
    padding: 8px;
  }

  .whatsapp-icon {
    font-size: 35px;
  }
}

/* Blue */
/* #218bdc */

/* Gray */
/* #373737 */

/* white */
/* #e7ecf0 */
/* #eaecee */

/* bg */
/* #212121 */
/* #0e2234 */
